export const NavData = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About RICC",
    path: "/about",
  },
  {
    name: "Our Mission",
    path: "/mission",
  },
  {
    name: "Membership",
    path: "/membership",
  },
  {
    name: "Programs",
    path: "/programs",
  },
  {
    name: "More",
    path: "/ministries",
  },
];
